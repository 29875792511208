<template>
  <FileUpload
    ref="fileUpload"
    mode="advanced"
    :dragdrop="true"
    :show-cancel-button="false"
    :accept="allowedFileExtensions"
    :show-upload-button="false"
    name="files[]"
    :custom-upload="true"
    :multiple="true"
    :auto="true"
    :max-file-size="maxFileSize"
    @uploader="doUpload"
  >
    <template #content="{ messages, onMessageClose }">
      <div v-if="messages && messages.length > 0" class="mb-4">
        <Message
          v-for="msg of messages"
          :key="msg"
          severity="error"
          @close="onMessageClose"
        >
          {{ msg }}
        </Message>
      </div>
      <div class="p-fileupload-content">
        <div v-if="theFilesPending.length > 0">
          <h5 class="text-lg font-semibold text-gray-800 mb-4 mt-2">Pending</h5>
          <div class="flex flex-wrap p-0 sm:p-20 gap-20">
            <div v-for="file of theFiles" :key="file?.id">
              <div
                class="card m-0 p-12 flex flex-col border border-surface items-center gap-6"
              >
                <div>
                  <img
                    v-if="file?.type == 'image'"
                    role="presentation"
                    :alt="file?.file?.name"
                    :src="file?.file?.objectURL"
                    width="100"
                    height="50"
                    class="shadow"
                  />
                </div>
                <span class="font-semibold">{{ file?.file?.name }}</span>
                <div>{{ formatFileSize(file?.file?.size) }}</div>
                <div>{{ progress[file?.id] }}% complete</div>
                <Badge value="Pending" severity="warn" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="theFilesUploaded.length > 0">
          <h5 class="text-lg font-semibold text-gray-800 mb-4 mt-2">
            Completed
          </h5>
          <div class="flex flex-wrap p-0 sm:p-20 gap-20">
            <div
              v-for="file of theFilesUploaded"
              :key="file?.id"
              class="card m-0 p-12 flex flex-col border border-surface items-center gap-6"
            >
              <div>
                <img
                  v-if="file?.type == 'image'"
                  role="presentation"
                  :alt="file?.file?.name"
                  :src="file?.file?.objectURL"
                  width="100"
                  height="50"
                  class="shadow"
                />
              </div>
              <span class="font-semibold">{{ file?.file?.name }}</span>
              <div>{{ formatFileSize(file?.file?.size) }}</div>
              <Badge value="Completed" class="mt-6" severity="success" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #empty>
      <div class="flex flex-col items-center">
        <div
          v-if="!theFilesPending.length && !theFilesUploaded.length"
          class="flex items-center justify-center flex-col bg-gray-100 w-full border border-gray-300"
        >
          <i
            class="pi pi-cloud-upload !border-2 !rounded-full !p-8 !text-4xl !text-muted-color mt-12"
          />
          <p class="my-6">Drag &amp; drop files</p>
        </div>
        <div class="text-sm text-gray-600 my-4">
          Allowed file types: {{ allowedFileExtensions }}
        </div>
      </div>
    </template>
  </FileUpload>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from "vue";
import { uploadFileMultipart } from "@/shared/services/file-upload";
import { UploadableFile } from "@/shared/datamodels/uploadableFile";
import { useToast } from "primevue/usetoast";
import FileUpload, { type FileUploadUploaderEvent } from "primevue/fileupload";
import Badge from "primevue/badge";
import Message from "primevue/message";
import {
  allowedVideoFileExtensionsByKey,
  allowedImageFileExtensionsByKey,
  allowedDocumentFileExtensionsByKey,
  allowedFileExtensionsByKey,
  allowedFileTypes,
  allowedImageTypes,
  allowedVideoTypes,
  allowedDocumentTypes,
} from "@/shared/constants/uploader";

const DEFAULT_MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024; // 2GB

const maxFileSize = computed(() => {
  return props.maxFileSize || DEFAULT_MAX_FILE_SIZE;
});

let theFiles = ref<UploadableFile[]>([]);
let theFilesUploaded = ref<UploadableFile[]>([]);
let theFilesPending = ref<string[]>([]);

const doUpload = async (d: FileUploadUploaderEvent) => {
  theFiles.value = [];
  progress.value = {};

  let rawFiles = Array.isArray(d?.files) ? d?.files : [];
  if (rawFiles.length === 0) {
    console.log("No files to upload. Skipping.");
    return;
  }

  for (let idx = 0; idx < rawFiles.length; idx++) {
    const uploadableFileContent = new UploadableFile(rawFiles[idx]);

    let matchesType: boolean;
    if (props.onlyImagesAllowed) {
      const fileType = uploadableFileContent?.file
        ?.type as keyof typeof allowedImageTypes;
      matchesType = allowedImageTypes[fileType];
    } else if (props.onlyVideosAllowed) {
      const fileType = uploadableFileContent?.file
        ?.type as keyof typeof allowedVideoTypes;
      matchesType = allowedVideoTypes[fileType];
    } else if (props.onlyDocumentsAllowed) {
      const fileType = uploadableFileContent?.file
        ?.type as keyof typeof allowedDocumentTypes;
      matchesType = allowedDocumentTypes[fileType];
    } else {
      const fileType = uploadableFileContent?.file
        ?.type as keyof typeof allowedFileTypes;
      matchesType = allowedFileTypes[fileType];
    }

    if (!matchesType) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: `Acceptable file types: ${allowedFileExtensionsByKey}`,
        life: 10000,
      });

      continue;
    }

    theFiles.value.push(uploadableFileContent);
    if (uploadableFileContent?.id) {
      progress.value[uploadableFileContent.id] = 0;
    }
  }

  let assetIds = [];

  const fileLength = theFiles.value.length;

  for (let idx = 0; idx < fileLength; idx++) {
    const currentFile = theFiles.value[idx];

    theFilesPending.value.push(currentFile?.id);

    const id = await uploadFileMultipart(
      props.organizationId,
      currentFile,
      myUploadProgress
    );
    assetIds.push(id);

    theFilesUploaded.value.push(currentFile);

    const pendingIndex = theFilesPending.value.indexOf(currentFile?.id);
    if (pendingIndex > -1) {
      theFilesPending.value.splice(pendingIndex, 1);
    }
  }

  emit("completed", assetIds);
};

const toast = useToast();

const props = defineProps<{
  label?: string;
  organizationId: number;
  clearPrevious?: boolean;
  onlyVideosAllowed?: boolean;
  onlyImagesAllowed?: boolean;
  onlyDocumentsAllowed?: boolean;
  maxFileSize?: number;
}>();

let allowedFileExtensions = computed(() => {
  if (props.onlyDocumentsAllowed) {
    return allowedDocumentFileExtensionsByKey;
  } else if (props.onlyImagesAllowed) {
    return allowedImageFileExtensionsByKey;
  } else if (props.onlyVideosAllowed) {
    return allowedVideoFileExtensionsByKey;
  }
  return allowedFileExtensionsByKey;
});

const emit = defineEmits<{
  (e: "completed", ids: number[]): void;
  (e: "error", error: string): void;
}>();

interface progressType {
  [key: string]: number;
}

let progress = ref<progressType>({});

const formatFileSize = (size: number) => {
  if (!size) {
    return "";
  }

  const index = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  const units = ["B", "kB", "MB", "GB", "TB"];
  const rawFilesize = size / Math.pow(1024, index);
  const filesize = rawFilesize.toFixed(2);
  return `${filesize} ${units[index]}`;
};

const myUploadProgress = (file: UploadableFile) => {
  if (file?.id && file?.progress) {
    progress.value[file.id] = file.progress;
  }
};

const fileUpload = ref<InstanceType<typeof FileUpload> | null>(null);

onMounted(async () => {
  watch(
    () => props.clearPrevious,
    () => {
      theFiles.value = [];
      theFilesUploaded.value = [];
      theFilesPending.value = [];
    }
  );
});
</script>

<style scoped>
.dropzone {
  background: #e0e0e0;
  transition:
    background,
    border 0.3s ease-in-out;
  cursor: pointer;
  height: 120px;
  border: 1px solid transparent;
}

.dropzone:hover,
.dropzone.is-dragging {
  background: #d0d0d0;
}

.dropzone.is-dragging {
  border-color: var(--v-theme-primary);
}
</style>
