<template>
  <AppCard class="mb-6">
    <div class="">
      <div class="space-y-4">
        <div v-if="isEdit">
          <div class="text-xl font-bold mb-6">
            {{ EDIT_INVOICE_FORM_TITLE }}
          </div>
        </div>

        <div v-if="!isEdit">
          <div class="text-xl font-bold mb-6">
            {{ CREATE_INVOICE_FORM_TITLE }}
          </div>
        </div>

        <div>
          <div class="mb-6">
            <label for="title" class="input-label"
              >Invoice Title <span class="subtext">(Internal) </span></label
            >
            <InputText
              id="title"
              v-model="invoiceTitle"
              type="text"
              placeholder="Title"
              class="main-input"
            />
          </div>

          <div>
            <label for="dueDate" class="input-label">Due Date</label>
            <DatePicker
              id="dueDate"
              v-model="invoiceDueDate"
              show-icon
              icon-display="input"
              placeholder="Due Date"
              class="main-input"
            />

            <small
              v-if="v$ErrorMessage(fieldValidations.invoiceDueDate.$errors)"
              class="error-message"
            >
              {{ v$ErrorMessage(fieldValidations.invoiceDueDate.$errors) }}
            </small>
          </div>
        </div>
        <LineItemTotal
          :invoice-total="invoiceTotal"
          :selected-recipients-count="selectedRecipients.length"
        />

        <div v-if="isEdit && invoiceStatus">
          <div class="mb-2">
            <label for="status" class="input-label info">Status</label>
            <p
              :style="{ color: getInvoiceStatusColor(invoiceStatus) }"
              class="subtext"
            >
              {{ formattedInvoiceStatus }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </AppCard>
  <CreateInvoiceItems
    v-model:lineItems="lineItems"
    :field-validations="fieldValidations"
  />

  <div>
    <InstallmentsConfiguration
      v-model:installmentsTermLength="installmentsTermLength"
      v-model:installmentsEnabled="installmentsEnabled"
      v-model:installmentsPaymentDay="installmentsPaymentDay"
      :invoiceTotal="invoiceTotal"
      :fieldValidations="fieldValidations"
    />
  </div>

  <div v-if="!isEdit">
    <Recipients
      v-model:selected-recipients="selectedRecipients"
      v-model:selected-teams="selectedTeams"
      :players="players"
      :teams="teams"
      :invoice-total="invoiceTotal"
    />
  </div>
</template>

<script setup lang="ts">
import DatePicker from "primevue/datepicker";
import InputText from "primevue/inputtext";

import CreateInvoiceItems from "@/modules/payments/components/CreateInvoiceItems.vue";
import LineItemTotal from "@/modules/payments/components/LineItemTotal.vue";
import Recipients from "./Recipients.vue";
import InstallmentsConfiguration from "./InstallmentsConfiguration.vue";

import { useRecipients } from "../composables/useRecipients";

import type { InvoiceFormProps, InvoiceFormFields } from "@/types";
import {
  getFormattedInvoiceStatus,
  getInvoiceStatusColor,
} from "@/modules/payments/helpers";

import { v$ErrorMessage } from "@/shared/utils/helpers";
import {
  CREATE_INVOICE_FORM_TITLE,
  EDIT_INVOICE_FORM_TITLE,
} from "@/modules/payments/constants";
import { computed, onMounted, watch } from "vue";
import AppCard from "@/shared/components/AppCard.vue";

const invoiceModel = defineModel<InvoiceFormFields>("invoiceFormFields", {
  required: true,
});

const { selectedRecipients, players, teams, selectedTeams, fetchRecipients } =
  useRecipients();

const {
  invoiceTitle,
  invoiceDueDate,
  lineItems,
  invoiceStatus,
  invoiceRecipients,
  installmentsTermLength,
  installmentsPaymentDay,
  installmentsEnabled,
} = invoiceModel.value;

const formattedInvoiceStatus = computed(() => {
  if (!invoiceStatus?.value) return "";

  return getFormattedInvoiceStatus(invoiceStatus.value);
});

const props = defineProps<InvoiceFormProps>();
const { fieldValidations, isEdit } = props;

const invoiceTotal = computed(() => {
  return lineItems.value.reduce(
    (acc, item) => acc + item.itemPrice * item.itemQuantity,
    0
  );
});

watch(
  selectedRecipients,
  () => {
    const transformedRecipients = selectedRecipients.value.map((recipient) => {
      return {
        playerId: recipient.id,
        playerName: recipient.name,
        associatedContactId:
          recipient.selectedContact?.id || recipient.defaultContact?.id,
      };
    });

    invoiceRecipients.value = transformedRecipients;
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  fetchRecipients();
});
</script>

<style>
.input-label {
  display: block;
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.subtext {
  font-weight: 200;
}

.main-input {
  width: 300px;
}

.error-message {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #e3342f;
  display: flex;
  align-items: center;
  opacity: 0.9;
}

.info {
  margin-bottom: -10px;
}
</style>
