import { type RouteRecordRaw } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import SubscriptionsList from "./views/SubscriptionsList.vue";
import SubscriptionCreate from "./views/SubscriptionCreate.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/payments/subscriptions",
    name: ROUTE_NAME.PAYMENT_SUBSCRIPTIONS,
    component: SubscriptionsList,
  },
  {
    path: "/payments/subscriptions/create",
    name: ROUTE_NAME.PAYMENT_SUBSCRIPTIONS_CREATE,
    component: SubscriptionCreate,
  },
];
