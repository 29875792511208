<template>
  <BaseLayout
    title="View Invoice"
    :breadcrumb="[
      {
        label: 'Invoices',
        to: {
          name: ROUTE_NAME.PAYMENT_INVOICES,
        },
      },
      {
        label: invoice?.invoice_title,
      },
    ]"
  >
    <div>
      <div class="fixed-action-bar justify-end rounded-md p-2">
        <Button
          type="button"
          label="View in Payment Portal"
          class="mr-2"
          @click="linkToPaymentPortal"
        />
        <Button
          type="button"
          label="View in Payabli"
          class="mr-2"
          @click="linkToPayabli"
        />
        <Button
          icon="pi pi-pencil"
          label="Edit"
          severity="secondary"
          :disabled="disablabledEditButton"
          @click="onClickEdit"
        />
      </div>
    </div>
    <div v-if="invoice">
      <Invoice :invoice="invoice" />
    </div>
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";
import Invoice from "../components/Invoice.vue";
import Button from "primevue/button";

import { useRoute } from "vue-router";
import router from "@/router";

import { useInvoice } from "../composables/useInvoice";
import { computed, onMounted } from "vue";
import { ROUTE_NAME } from "@/shared/constants/routes";
import {
  CANCELLED_INVOICE,
  PAID_INVOICE,
  PARTIALLY_PAID_INVOICE,
} from "../constants";

const { fetchInvoice, invoice } = useInvoice();
const route = useRoute();

const invoiceId = route.params.id.toString();

onMounted(async () => {
  await fetchInvoice(invoiceId);
});

const onClickEdit = () => {
  router.push({ name: ROUTE_NAME.EDIT_INVOICE, params: { id: invoiceId } });
};

const linkToPayabli = () => {
  const url = invoice.value?.payabli_payhub_invoice_url;

  if (!url) return;

  window.open(url, "_blank");
};

const linkToPaymentPortal = () => {
  const url = invoice.value?.portal_invoice_url;

  if (!url) return;

  window.open(url, "_blank");
};

const disablabledEditButton = computed(() => {
  const notEditableStatuses = [
    PAID_INVOICE,
    CANCELLED_INVOICE,
    PARTIALLY_PAID_INVOICE,
  ];

  if (!invoice?.value?.invoice_status) return;
  if (!invoice.value.is_tfa_created_invoice) return;

  return notEditableStatuses.includes(invoice.value?.invoice_status);
});
</script>

<style scoped>
.fixed-action-bar {
  display: flex;
  width: 100%;
}
</style>
