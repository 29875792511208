/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

/**
 * * `image` - Image
* `video` - Video
* `document` - Document
* `other` - Other
 */
export type AssetType = typeof AssetType[keyof typeof AssetType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssetType = {
  image: 'image',
  video: 'video',
  document: 'document',
  other: 'other',
} as const;
