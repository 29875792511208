import {
  adminV1PaymentsSubscriptionPlansCreate,
  adminV1PaymentsSubscriptionPlansList,
} from "@/api/admin-payments-subscription-plans/admin-payments-subscription-plans";
import type {
  PaginatedSubscriptionPlanList,
  SubscriptionDocument,
  SubscriptionPlan,
} from "@/api/model";
import type { AdminV1PaymentsSubscriptionPlansListParams } from "@/api/model/adminV1PaymentsSubscriptionPlansListParams";
import type { NonReadonly, SubscriptionPlanForm } from "@/types";

export const fetchSubscriptions = async (): Promise<SubscriptionPlan[]> => {
  const params: AdminV1PaymentsSubscriptionPlansListParams = {
    limit: 1000,
    offset: 0,
  };
  const response: PaginatedSubscriptionPlanList =
    await adminV1PaymentsSubscriptionPlansList(params);
  if (!response.results) {
    console.error("Failed to fetch subscriptions");
    return [];
  }
  return response.results;
};

export const createSubscriptionPlan = async (
  subscriptionPlan: SubscriptionPlanForm
): Promise<SubscriptionPlan> => {
  const docs: SubscriptionDocument[] =
    subscriptionPlan.subscription_documents.map((doc) => ({
      asset_id: doc.asset_id,
      asset: null as any,
      created_at: "",
      id: "",
      updated_at: "",
    }));

  const subscriptionPlanData: NonReadonly<SubscriptionPlan> = {
    amount: subscriptionPlan.amount,
    description: subscriptionPlan.description,
    documents_require_acceptance: subscriptionPlan.documents_require_acceptance,
    enrollment_status: subscriptionPlan.enrollment_status,
    payment_frequency: subscriptionPlan.payment_frequency,
    published: subscriptionPlan.published,
    subscription_documents: docs,
    title: subscriptionPlan.title,
  };
  const response: SubscriptionPlan =
    await adminV1PaymentsSubscriptionPlansCreate(subscriptionPlanData);
  return response;
};
