/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

/**
 * * `weekly` - Weekly
* `every_two_weeks` - Every2Weeks
* `monthly` - Monthly
* `quarterly` - Quarterly
* `every_six_months` - Every6Months
* `annually` - Annually
 */
export type PaymentFrequencyEnum = typeof PaymentFrequencyEnum[keyof typeof PaymentFrequencyEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentFrequencyEnum = {
  weekly: 'weekly',
  every_two_weeks: 'every_two_weeks',
  monthly: 'monthly',
  quarterly: 'quarterly',
  every_six_months: 'every_six_months',
  annually: 'annually',
} as const;
