import { AssetType } from "@/shared/datamodels/asset";

export class UploadableFile {
  file: any;
  id: string;
  url: string;
  status: null;
  progress: number;
  type: AssetType;
  constructor(file: File) {
    this.file = file;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
    this.url = URL.createObjectURL(file);
    this.status = null;
    this.progress = 0;
    this.type = AssetType.other;
    if (file.type.startsWith(AssetType.video)) {
      this.type = AssetType.video;
    }
    if (file.type.startsWith(AssetType.image)) {
      this.type = AssetType.image;
    }
    if (file.type.startsWith(AssetType.document)) {
      this.type = AssetType.document;
    }
  }
}
