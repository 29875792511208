/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

/**
 * * `open` - Open
* `closed` - Closed
 */
export type EnrollmentStatusEnum = typeof EnrollmentStatusEnum[keyof typeof EnrollmentStatusEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnrollmentStatusEnum = {
  open: 'open',
  closed: 'closed',
} as const;
