/*
   uploaders filter a user's file selection by file extension. 
   For example, that the file ends in .png. 
   
   We then check the file's contentType to verify that matches.
*/

/* 
  video uploader 
  make sure to set both allowedVideoTypes and allowedVideoFileExtensions
*/

export const allowedVideoTypes = {
  "video/quicktime": true,
  "video/mp4": true,
};

// dot in the file extension is required
export const allowedVideoFileExtensions = {
  ".mp4": true,
  ".mov": true,
};

export const allowedVideoFileExtensionsByKey = Object.keys(
  allowedVideoFileExtensions
).join(", ");

/* 
  image uploader 
  make sure to set both allowedVideoTypes and allowedVideoFileExtensions
*/

export const allowedImageTypes = {
  "image/png": true,
  "image/jpeg": true,
};

// dot in the file extension is required
export const allowedImageFileExtensions = {
  ".png": true,
  ".jpg": true,
  ".jpeg": true,
};

export const allowedImageFileExtensionsByKey = Object.keys(
  allowedImageFileExtensions
).join(", ");

/* 
  document uploader 
  make sure to set both allowedDocumentTypes and allowedDocumentFileExtensions
*/

export const allowedDocumentTypes = {
  "application/pdf": true,
  "application/msword": true,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    true,
};

// dot in the file extension is required
export const allowedDocumentFileExtensions = {
  ".pdf": true,
  ".doc": true,
  ".docx": true,
};

export const allowedDocumentFileExtensionsByKey = Object.keys(
  allowedDocumentFileExtensions
).join(", ");

/* 
  image and video uploader in one 
  just merges image and video together
*/

export const allowedFileTypes = Object.assign(
  allowedVideoTypes,
  allowedImageTypes,
  allowedDocumentTypes
);

export const allowedFileExtensions = Object.assign(
  allowedVideoFileExtensions,
  allowedImageFileExtensions
);
export const allowedFileExtensionsByKey = Object.keys(
  allowedFileExtensions
).join(", ");
